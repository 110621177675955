.about-part {
  position: relative;
  background: var(--color-background);
  padding-bottom: 32px;

  .cards-container {
    visibility: hidden;
    width: calc(100% - 98px);
    position: absolute;
    transform: translateY(-50%);
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    padding: 0 48px;
    @media (max-width: 1200px) {
      padding: 64px 16px;
    }

    @media (max-width: 1200px) {
      flex-direction: column;
      padding: 0 16px;
      width: calc(100% - 32px);
      transform: translateY(-60px);
    }
  }

  .card {
    flex-basis: 33%;
    margin-right: 24px;
    border-radius: 0px;
    background-color: var(--color-white);

    @media (max-width: 1200px) {
      margin-right: 0;
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    .full-mode {
      display: flex;
      @media (max-width: 1200px) {
        display: none;
      }

      img {
        width: 74px;
        height: 74px;
        margin-right: 16px;
      }

      .title {
        margin-bottom: 8px;
      }
    }
    .small-mode {
      @media (min-width: 1200px) {
        display: none;
      }
      img {
        width: 38px;
        height: 38px;
        margin-right: 16px;
      }
      .inline {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
      }
    }
  }
}
