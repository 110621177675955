.commission-part {
  background: var(--color-background-light);
  padding: 64px 48px;
  @media (max-width: 1200px) {
    padding: 64px 16px;
  }

  .commission-part__inner {
    display: flex;
    align-items: stretch;
    justify-content: center;

    @media (max-width: 1200px) {
      flex-direction: column;
    }

    .card {
      text-align: center;
      flex-basis: 30%;
      margin-right: 24px;
      background-color: var(--color-white);
      border-radius: 0px;

      @media (max-width: 1200px) {
        margin-right: 0;
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .title {
        margin-bottom: 8px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
