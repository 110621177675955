footer.appbar {
  background: var(--color-background);
  position: static;

  @media (max-width: 1200px) {
    display: none;
  }
}

._short {
  background: var(--color-header);
  width: 100%;
  display: flex;
  justify-content: center;
  height: 38px;
  padding-top: 8px;
  padding-bottom: 8px;

  @media (min-width: 1200px) {
    display: none;
  }
}

