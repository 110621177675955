html {
    --color-primary: #3356e9;
    --color-primary-hover: #3d5bdb;
    --color-disabled: #6c757d;
    --color-white: #FFFFFF;
    --color-text: #000000;
    --color-text-gray: #6c757d;
    --color-placeholder: #989898;
    --color-header: #ffffff;
    --color-background-light: #ffffff;
    --color-background: #f8f9fa;
    --color-dark: #262c38;
    --color-black: #000000;
}
